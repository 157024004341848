import { ACCOUNT_TYPES } from '@lib/userAuth'

// start MK design
export const TEAL_FURBY = '#2a687b'

export const TEAL_MONOPOLY = '#BAE8E6'

export const TEAL_GLOVE = '#87C5C7'
export const BOSTON_BLUE = '#409AA6'
export const HALF_BACKED = '#487C5C7'
export const ICE_BERG = '#DAF5F3'
export const TEAL_DOLLHOUSE = '#004359'
export const S_NUFF = '#dbd0eb'
export const TEAL_POGS = '#dcf7f3'
export const TEAL_ROBOTS = '#3b9399'
export const TEAL_LITEBRITE = '#ecfafb'
export const TEAL_POWERGLOVE = '#73c2ba'
export const PURPLE_SHOPKINS = '#3f3159'
export const GREEN_GLO_WORM = '#f1f5c6'
export const GREEN_CABBAGE_PATCH = '#414f28'
export const GREEN_TMNT = '#95AB5E'
export const GREEN_MOON_SHOES = '#CFDE9E'
export const PURPLE_KOOSH = '#f0ebf7'
export const PURPLE_TROLLS = '#5a476e'
export const PURPLE_BEANIE_BABY = '#dcd1eb'
export const PURPLE_BOP_IT = '#7A6096'
export const GRAY_WII = '#F7F6F5'
export const GRAY_PLAYSTATION = '#615C5A'
export const GRAY_NES = '#DEDBD9'
export const RED_SURPRISE = '#FFF2F5'
export const RED_JEM = '#D96483'
export const RED_STRAWBERRY_SHORT_CAKE = '#ba4967'
export const RED_POWERRANGERS = '#853852'
export const RED_DARK = '#69253e'
export const GRAY_NICKELARCADE = '#918d8a'
export const YELLOW_GARFIELD = '#C79F50'

export const DARKER_GRAY = '#EFEEED'

export const YELLOW_SUPER_SOAKER = '#FBE7AA'
export const PURPLE_MEGATRON = '#a08abf'
export const PURPLE_POLLYPOCKET = '#bcabd1'
export const GOLDER_ROD = '#FFF5D9'
export const GOLDER_ROD_1000 = '#B38300'

// start Pop colors
export const PRIMARY_TEXT_COLOR = '#545454'
export const SECONDARY_TEXT_COLOR = '#404040'
export const THIRD_TEXT_COLOR = '#95949D'

export const SECONDARY_COLOR = '#DD2476'

export const TERTIARY_COLOR_LIGHT = '#6fcdff'
export const TERTIARY_COLOR_MAIN = '#2D9CDB'
export const TERTIARY_COLOR_DARK = '#006ea9'
export const TERTIARY_COLOR_LIGHT_2X = '#F4FAFD'

export const LIGHT_GRAY = '#f4f4f4'
export const LIGHT_BLUE = '#dfeff8'

export const PRIMARY_BLACK_BACKGROUND = '#151B26'
export const NAV_ITEM_HOVER_BACKGROUND = '#F3F3F3'

export const BOX_BORDER_COLOR = '#E8E9EA'
export const PRIMARY_GREY = '#888888'
export const SECONDARY_GREY = '#666666'
export const THIRD_GREY = '#747474'
export const FOURTH_GREY = '#404040'
export const FIFTH_GREY = '#9B9B9B'

export const ICON_GREY_PRIMARY = '#D1D1D1'
export const ICON_GREY_SECONDARY = '#C6C6C6'
export const ICON_GREY_TERTIARY = '#979797'

export const PRIMARY_GREY_BACKGROUND = '#f8f8f8'
export const SECONDARY_GREY_BACKGROUND = '#ECECEE'
export const THIRD_GREY_BACKGROUND = '#FBFBFB'
export const FOURTH_GREY_BACKGROUND = '#F7F7F7'
export const FIFTH_GREY_BACKGROUND = '#E4E4E4'
export const PRIMARY_GREY_BORDER = '#ececec'
export const PRIMARY_GREY_SEPARATOR = '#DDDDDD'

export const PRIMARY_TEXT_GREY = '#b4b4b4'
export const SECONDARY_TEXT_GREY = '#d8d8d8'
export const DARK_PURPLE = '#5A476E'
export const LIGHT_PURPLE = '#7A6096'

export const PRIMARY_BLUE_BACKGROUND = '#98D0F0'
export const SECONDARY_BLUE_BACKGROUND = '#2196F3'
export const THIRD_BLUE_BACKGROUND = '#96cded'

export const PRIMARY_ICON_COLOR = '#94949e'
export const PRIMARY_RED = '#E25B5B'
export const TOAST_BORDER = '#FFBDBD'

export const BRAND_GREEN = '#53a673'

// npi colors
export const NPI_GREY = '#F9F9F9'
export const NPI_FILES_TABLE_BACKGROUND = '#f3f4f8'
export const GOOD_STATUS = '#43A047'
export const MEDIUM_STATUS = '#F9A825'
export const BAD_STATUS = '#EF3E3E'
export const IN_PROGRESS_STATUS = '#eff4fa'
export const DONE_STATUS = '#eefbf3'
export const NPI_BLUE = '#7fb8c5'
// end npi colors

export const USER_TYPE_COLORS = {
  [ACCOUNT_TYPES.GEMBAH]: PRIMARY_BLACK_BACKGROUND,
  [ACCOUNT_TYPES.EXPERT]: PRIMARY_BLUE_BACKGROUND,
  [ACCOUNT_TYPES.CREATOR]: PRIMARY_RED,
  UNKNOWN: 'transparent',
}

// end Pop colors

export const INACTIVE_GRAY = '#818080'

export const BRAND_WHITE = '#ffffff'

export const COMMON_BLACK = '#212224'
export const COMMON_WHITE = '#FFF'

export const BACKGROUND_DEFAULT = '#FFF'
export const BACKGROUND_CHECKLIST_CUSTOMER = '#EFF1F1'

export const PRIMARY_LIGHT = '#656060'

export const ERROR_MAIN = '#8F1741'
export const ERROR_LIGHT = '#FF8484'

export const WARNING_LIGHT = '#BF8A40'

export const SUCCESS_MAIN = '#08912C'

export const GRAYSCALE_MEDIUM = '#A4A4A4'
export const GRAYSCALE_LIGHTER = '#C4C4C4'
export const GRAYSCALE_LIGHTEST = '#F2F2F2'

export const CHAT_DIRECT_LINK_BACKGROUND = '#FFF1CB'
export const CHAT_UNREAD_MESSAGE_BACKGROUND = '#EDEDED'
export const GRAY_GAMEBOY = '#C6C1BD'
