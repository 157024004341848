import { maxBy } from 'lodash'

import { USER_ID_SESSION_STORAGE_KEY } from '@lib/constants'

export const ACCOUNT_TYPES = {
  GEMBAH: 'GEMBAH',
  CREATOR: 'CREATOR',
  EXPERT: 'EXPERT',
  FACTORY: 'FACTORY',
}

export const ACCOUNT_TYPE_LABELS = {
  [ACCOUNT_TYPES.GEMBAH]: 'Gembah',
  [ACCOUNT_TYPES.CREATOR]: 'Creator',
  [ACCOUNT_TYPES.EXPERT]: 'Expert',
  [ACCOUNT_TYPES.FACTORY]: 'Factory',
}

export const USER_ROLES = {
  OWNER: 'OWNER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  BILLING: 'BILLING',
  CONTACT: 'CONTACT',
  MEMBER: 'MEMBER',
}

export const USER_ROLES_HIERARCHY = {
  [USER_ROLES.OWNER]: 3,
  [USER_ROLES.SUPER_ADMIN]: 3,
  [USER_ROLES.ADMIN]: 2,
  [USER_ROLES.BILLING]: 2,
  [USER_ROLES.CONTACT]: 1,
  [USER_ROLES.MEMBER]: 1,
}

export const USER_ROLES_LABELS = {
  [USER_ROLES.OWNER]: 'Owner',
  [USER_ROLES.SUPER_ADMIN]: 'Super Admin',
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.BILLING]: 'Admin',
  [USER_ROLES.CONTACT]: 'Member',
  [USER_ROLES.MEMBER]: 'Member',
}

export const ACCOUNT_USER_STATUS = {
  INVITE_PENDING: 'INVITE_PENDING',
  INVITE_ACCEPTED: 'INVITE_ACCEPTED',
  ONBOARDING_COMPLETE: 'ONBOARDING_COMPLETE',
}

export const LEGACY_USER_GROUPS = {
  NPI_SUPER: 'gembah-npi-super',
  EXECUTIVE: 'gembah-executive',
  DEVELOPER: 'gembah-developer',
  CUSTOMER: 'customer-user',
}

export const maxRole = (accountUser) =>
  maxBy(accountUser?.roles, ({ role }) => USER_ROLES_HIERARCHY[role])

export const maxPermission = (accountUser) => USER_ROLES_HIERARCHY[maxRole(accountUser)?.role] ?? 1

const checkPermission = (permission) => (accountUser) => {
  if (!permission || !accountUser) return false

  return Object.entries(permission).some(([accountType, minPermissionLevel]) => {
    const hasCorrectAccountType = accountUser?.account?.type === accountType
    if (!minPermissionLevel) return hasCorrectAccountType

    return (
      hasCorrectAccountType &&
      maxPermission(accountUser) >= USER_ROLES_HIERARCHY[minPermissionLevel]
    )
  })
}

export const hasPermission = {
  CREATOR: checkPermission({ [ACCOUNT_TYPES.CREATOR]: null }),
  EXPERT: checkPermission({ [ACCOUNT_TYPES.EXPERT]: null }),
  FACTORY: checkPermission({ [ACCOUNT_TYPES.FACTORY]: null }),
  GEMBAH: checkPermission({ [ACCOUNT_TYPES.GEMBAH]: null }),
  ADMIN: checkPermission(
    Object.values(ACCOUNT_TYPES).reduce(
      (result, accountType) => ({ ...result, [accountType]: USER_ROLES.ADMIN }),
      {}
    )
  ),
  OWNER: checkPermission(
    Object.values(ACCOUNT_TYPES).reduce(
      (result, accountType) => ({ ...result, [accountType]: USER_ROLES.OWNER }),
      {}
    )
  ),
  EXTERNAL: checkPermission({
    [ACCOUNT_TYPES.CREATOR]: null,
    [ACCOUNT_TYPES.EXPERT]: null,
    [ACCOUNT_TYPES.FACTORY]: null,
  }),
  GEMBAH_OR_EXPERT: checkPermission({
    [ACCOUNT_TYPES.EXPERT]: null,
    [ACCOUNT_TYPES.GEMBAH]: null,
  }),
  GEMBAH_ADMIN: checkPermission({ [ACCOUNT_TYPES.GEMBAH]: USER_ROLES.ADMIN }),
  GEMBAH_SUPER: checkPermission({ [ACCOUNT_TYPES.GEMBAH]: USER_ROLES.OWNER }),
  PROJECT_CONTRACTOR_RESOURCES_VIEW: checkPermission({
    [ACCOUNT_TYPES.EXPERT]: null,
    [ACCOUNT_TYPES.GEMBAH]: USER_ROLES.ADMIN,
  }),
}

export const hasUserGroup = (user, allowedGroups = []) => {
  const userGroups = user?.groups ?? []
  if (!userGroups.length) return false
  return userGroups.some((group) => allowedGroups.includes(group))
}

export const isManager = (managingAccountUser, managedAccountUser) => {
  if (
    !managingAccountUser?.account?.type ||
    managingAccountUser?.account?.type !== managedAccountUser?.account?.type
  )
    return false
  return maxPermission(managingAccountUser) >= maxPermission(managedAccountUser)
}

const isGembahManagingThirdParty = (managing, managed) =>
  hasPermission.GEMBAH_ADMIN(managing) &&
  !hasPermission.GEMBAH(managed) &&
  !hasPermission.CREATOR(managed)

export const isProjectManager = (managingAccountUser, managedAccountUser) => {
  if (isGembahManagingThirdParty(managingAccountUser, managedAccountUser)) return true
  return isManager(managingAccountUser, managedAccountUser)
}

export const isAdminManager = (managingAccountUser, managedAccountUser) => {
  if (managingAccountUser?.id === managedAccountUser?.id) return false
  if (!hasPermission.ADMIN) return false
  if (hasPermission.GEMBAH(managingAccountUser)) return true
  return isManager(managingAccountUser, managedAccountUser)
}

export const switchTo = (event, user) => {
  event.stopPropagation()
  localStorage.setItem(USER_ID_SESSION_STORAGE_KEY, user.id)
  window.open('/')
}

export const getChatChannels = (project, accountUser) => {
  if (!project || !accountUser) return {}

  const channelSubscriptions = {
    externalChatChannel: true,
    internalChatChannel: true,
    designChatChannel: true,
  }
  if (hasPermission.GEMBAH(accountUser)) return channelSubscriptions

  return Object.keys(channelSubscriptions).reduce((soFar, key) => {
    return {
      ...soFar,
      [key]: !!project[key]?.subscribed,
    }
  }, {})
}
